import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchType } from 'modules/search/types/SearchType';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { updateSearchLocation } from 'store/mainSearch/mainSearch.actions';

import { SearchPageLocationState } from './types/SearchPageLocationState';
import { useSearchPageSyncUrlAndFetch } from './useSearchPageSyncUrlAndFetch';

type Args = {
  searchType: SearchType;
  getSearchTrackingData: () => Record<string, unknown>;
  searchFacets: SearchFacet[];
};

export function useSearchPageLifecycle({
  searchType,
  getSearchTrackingData,
  searchFacets,
}: Args) {
  const location = useLocation<SearchPageLocationState>();
  const dispatch = useMainDispatch();

  const locationStateSearchLocation = location.state?.searchLocation;
  useEffect(() => {
    if (locationStateSearchLocation)
      dispatch(updateSearchLocation(locationStateSearchLocation, true));
  }, [locationStateSearchLocation, dispatch]);

  // Sync & fetch
  const { initialized } = useSearchPageSyncUrlAndFetch({
    searchType,
    getSearchTrackingData,
    searchFacets,
  });

  return { initialized };
}
