import { useSearchParams } from 'react-router-dom';

import { useMainSelector } from 'store/hooks/useMainSelector';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';

export function useSearchReady() {
  const { isEnvironmentLoaded } = useUserEnvironment();

  const initialSearchLocationSet = useMainSelector(
    (state) => typeof state.mainSearch.searchLocation !== 'undefined',
  );

  const [searchParams] = useSearchParams();
  const synonymId = searchParams.get('synonymId');

  const jobFamilyFacetLoaded = useMainSelector(
    (state) =>
      !synonymId ||
      state.mainSearch.jobFamilyFacetData?.jobSynonym.id === synonymId,
  );

  const mainSearchReady =
    jobFamilyFacetLoaded && isEnvironmentLoaded && initialSearchLocationSet;

  return { mainSearchReady };
}
